import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ComputerIcon from '@mui/icons-material/Computer';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AudioPlayer from '../../components/AudioPlayer/component';
import DateTimeInput from '../../components/DateTimeInput/component';
import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectUserType } from '../../state/userSlice';
import { CONVERSATION_ACTOR, USER_ROLES } from '../../utils/constants/system';
import { CALL_DIRECTION } from '../../utils/constants/twilio';
import { formatInternationalPhone } from '../../utils/helpers';

export default function ContactAttempts({ campaignId }) {
  const { getAccessTokenSilently } = useAuth0();
  const userType = useSelector(selectUserType);
  const [loading, setLoading] = useState(true);
  const [attempts, setAttempts] = useState([]);

  useEffect(() => {
    getContactAttempts();
  }, []);

  const getContactAttempts = async () => {
    try {
      const token = await getAccessTokenSilently();
      const attempts = await HiroApi.getContactAttempts(campaignId, token);

      setAttempts(attempts);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addContactAttempt = async () => {
    try {
      const token = await getAccessTokenSilently();
      const newAttempt = await HiroApi.addContactAttempt(campaignId, { from: CONVERSATION_ACTOR.COORDINATOR }, token);

      setAttempts([...attempts, newAttempt]);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteContactAttempt = async (attemptId, index) => {
    try {
      const token = await getAccessTokenSilently();
      const filtered = [...attempts.slice(0, index), ...attempts.slice(index + 1)];

      await HiroApi.deleteContactAttempt(campaignId, attemptId, token);
      setAttempts(filtered);
    } catch (error) {
      console.log(error);
    }
  };

  const updateContactAttempt = async (attempt) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.updateContactAttempt(campaignId, attempt.id, attempt, token);
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactTypeChange = async (event, index) => {
    const type = event.target.value;
    const attemptsCopy = [...attempts];

    attemptsCopy[index].type = type;
    setAttempts(attemptsCopy);
    await updateContactAttempt(attempts[index]);
  };

  const handleContactEngagementChange = async (event, index) => {
    const engagement = event.target.value;
    const attemptsCopy = [...attempts];

    attemptsCopy[index].engagement = engagement;
    setAttempts(attemptsCopy);
    await updateContactAttempt(attempts[index]);
  };

  const handleContactTimeChange = (value, index) => {
    const attemptsCopy = [...attempts];

    attemptsCopy[index].timestamp = dayjs(value).utc().format();
    setAttempts(attemptsCopy);
    updateContactAttempt(attempts[index]);
  };

  const isLastAttempt = (attempts, index) => {
    return index === attempts.length - 1;
  };

  const showRecording = (attempt) => {
    if (isEmpty(attempt.call?.recordings)) {
      return false;
    }

    if (userType === USER_ROLES.ADMIN) {
      return true;
    }

    return attempt.call?.recordings[0]?.source === 'RecordVerb';
  };

  const updateRecordingReadStatus = (attempt, index) => {
    const attemptsCopy = [...attempts];
    const updatedAttempt = { ...attempt };

    if (updatedAttempt.call?.recordings?.[0]) {
      updatedAttempt.call.recordings[0].isRead = true;
    }

    attemptsCopy[index] = updatedAttempt;
    setAttempts(attemptsCopy);
  };

  const renderDirectionIcon = (attempt) => {
    if (attempt?.call?.direction === CALL_DIRECTION.OUTGOING || attempt?.type === 'SMS') {
      return <CallMadeIcon sx={{ cursor: 'pointer' }} />;
    }

    if (attempt?.call?.direction === CALL_DIRECTION.INCOMING) {
      return <CallReceivedIcon sx={{ cursor: 'pointer' }} />;
    }

    return <span />;
  };

  const displayAttempts = () => {
    if (attempts.length) {
      return attempts.map((attempt, index, attempts) => (
        <div key={attempt.id} className="attempt">
          {attempt.from === CONVERSATION_ACTOR.COORDINATOR ? <PersonIcon /> : <ComputerIcon />}

          {attempt.type === 'SMS' ? (
            renderDirectionIcon(attempt)
          ) : (
            <Tooltip
              title={`From: ${formatInternationalPhone(attempt?.call?.from)} \n To: ${formatInternationalPhone(
                attempt?.call?.to
              )}`}
            >
              {renderDirectionIcon(attempt)}
            </Tooltip>
          )}

          <FormControl>
            <Select
              className="type"
              label="Type"
              value={attempt.type}
              onChange={(event) => handleContactTypeChange(event, index)}
              disabled={attempt.from === CONVERSATION_ACTOR.SYSTEM || attempt.from === CONVERSATION_ACTOR.PATIENT}
            >
              <MenuItem value="Call">Call</MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <Select
              className="type"
              label="Engagement"
              value={attempt.engagement}
              onChange={(event) => handleContactEngagementChange(event, index)}
            >
              <MenuItem value>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>

          <DateTimeInput
            disabled={attempt.from === CONVERSATION_ACTOR.SYSTEM || attempt.from === CONVERSATION_ACTOR.PATIENT}
            label="Date/Time"
            value={attempt.timestamp}
            index={index}
            onChange={handleContactTimeChange}
          />

          <div className="actions">
            {attempt.from === CONVERSATION_ACTOR.COORDINATOR ? (
              <IconButton onClick={() => deleteContactAttempt(attempt.id, index)}>
                <DeleteOutlineIcon className="add" />
              </IconButton>
            ) : null}

            {showRecording(attempt) ? (
              <AudioPlayer
                attempt={attempt}
                index={index}
                recording={attempt?.call?.recordings[0]}
                updateRecordingReadStatus={updateRecordingReadStatus}
              />
            ) : null}

            {isLastAttempt(attempts, index) ? (
              <IconButton onClick={addContactAttempt}>
                <AddBoxOutlinedIcon className="add" />
              </IconButton>
            ) : null}
          </div>
        </div>
      ));
    }

    return (
      <IconButton onClick={addContactAttempt}>
        <AddBoxOutlinedIcon className="add" />
      </IconButton>
    );
  };

  return (
    <div className="contact-attempts">
      <div className="header">
        <h3>Contact Attempts</h3>
        {loading ? (
          <Loader size={35} />
        ) : (
          <IconButton onClick={getContactAttempts}>
            <RefreshIcon />
          </IconButton>
        )}
      </div>
      {loading ? null : displayAttempts()}
    </div>
  );
}
