import './style.scss';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { debounce } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentFacility } from '../../state/systemSlice';
import { DEBOUNCE_TIME_LONG } from '../../utils/constants/system';

// FIXME: Daylight Savings not taken into account
dayjs.extend(utc);

export default function DateTimeInput(props) {
  const facility = useSelector(selectCurrentFacility);
  const { disabled, index, label, onChange, value } = props;

  const debouncedOnChange = useRef(debounce((value, index) => onChange(value, index), DEBOUNCE_TIME_LONG)).current;

  const handleChange = useCallback(
    (value) => {
      debouncedOnChange(value, index);
    },
    [debouncedOnChange, index]
  );

  return (
    <div className="date-time-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          disabled={disabled}
          label={label}
          value={dayjs(value).tz(facility.timezone)}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </div>
  );
}
