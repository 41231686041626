export const CALL_EVENT = {
  ACCEPT: { value: 'accept', label: 'Call in progress' },
  CANCEL: { value: 'cancel', label: 'Cancelled' },
  DISCONNECT: { value: 'disconnect', label: 'Disconnected' },
  ERROR: { value: 'error', label: 'Error' },
  MUTE: { value: 'mute', label: 'Muted' },
  RECONNECTED: { value: 'reconnected', label: 'Reconnected' },
  RECONNECTING: { value: 'reconnecting', label: 'Reconnecting' },
  REJECT: { value: 'reject', label: 'Rejected' },
  RINGING: { value: 'ringing', label: 'Ringing' },
};

export const DEVICE_STATUS = {
  DESTROYED: { value: 'destroyed', label: 'Unavailable' },
  ERROR: { value: 'error', label: 'Error' },
  INCOMING: { value: 'incoming', label: 'Call incoming' },
  REGISTERED: { value: 'registered', label: 'Ready' },
  REGISTERING: { value: 'registering', label: 'Loading' },
  TOKEN_WILL_EXPIRE: { value: 'tokenWillExpire', label: 'Token will expire' },
  UNREGISTERED: { value: 'unregistered', label: 'Unavailable' },
};

export const CALL_DIRECTION = {
  INCOMING: 'inbound',
  OUTGOING: 'outbound-dial',
};
