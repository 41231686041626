import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TodayIcon from '@mui/icons-material/Today';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { debounce, findIndex, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import BottomSheet from '../../components/BottomSheet/component';
import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility, selectProviders } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { DEBOUNCE_TIME, USER_ROLES } from '../../utils/constants/system';
import {
  formatDate,
  formatDatetime,
  getProviderInfo,
  getProviderName,
  getSpecialtyInfo,
  mapEngagementStatus,
} from '../../utils/helpers';
import Appointments from '../PatientProfile/Appointments';
import MessageHistory from '../PatientProfile/MessageHistory';
import PastPatientAppointments from '../PatientProfile/PastPatientAppointments';
import PatientAppointments from '../PatientProfile/PatientAppointments';
import PatientWizard from '../PatientProfile/PatientWizard';
import ProvidersDrawer from '../PatientProfile/ProvidersDrawer';
import CampaignAudit from './CampaignAudit';
import ContactAttempts from './ContactAttempts';
import PhoneNumbers from './PhoneNumbers';

export default function Campaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const facility = useSelector(selectCurrentFacility);
  const providers = useSelector(selectProviders);
  const userType = useSelector(selectUserType);
  const [loading, setLoading] = useState(true);
  const [isAuditOpen, setIsAuditOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [collapseDischargeDetails, setCollapseDischargeDetails] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [campaign, setCampaign] = useState({
    autopilot: true,
    messages: [],
    contactAttempts: [],
    status: '',
    providers: [],
    referrals: [],
  });

  const campaignId = location.pathname.split('/').pop();

  useEffect(() => {
    if (!isEmpty(facility)) {
      getCampaign();
    }
  }, []);

  const getCampaign = async () => {
    try {
      setLoading(true);

      const token = await getAccessTokenSilently();
      const campaign = await HiroApi.getPatientCampaign(campaignId, token);

      setCampaign(campaign);
      setPatientId(campaign.patientId);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getCampaignSilently = async () => {
    try {
      const token = await getAccessTokenSilently();
      const campaign = await HiroApi.getPatientCampaign(campaignId, token);

      setCampaign(campaign);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCampaign = async (key, payload) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.updateCampaign(campaignId, { [key]: payload }, token);
    } catch (error) {
      console.log(error);
    }
  };

  const updateReferralStatus = async (event, referralId) => {
    try {
      const token = await getAccessTokenSilently();
      const newStatus = event.target.value;
      const referralIndex = findIndex(campaign.referrals, ['id', referralId]);
      const updatedReferrals = [...campaign.referrals];

      await HiroApi.updateReferralStatus(campaign.id, referralId, { status: newStatus }, token);
      updatedReferrals[referralIndex].status = newStatus;
      setCampaign({ ...campaign, referrals: updatedReferrals });
      await getCampaignStatus();
    } catch (error) {
      console.log(error);
    }
  };

  const updatePatientTag = async () => {
    try {
      const token = await getAccessTokenSilently();
      const tag = isEmpty(campaign.tag) ? 'saved' : '';

      await HiroApi.updatePatient(campaign.patientId, { tag }, token);
      setCampaign({
        ...campaign,
        tag,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCampaignStatus = async () => {
    try {
      const token = await getAccessTokenSilently();
      const campaignStatus = await HiroApi.getCampaignStatus(campaignId, token);

      setCampaign({ ...campaign, status: campaignStatus });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleNeedsAction = async () => {
    try {
      await updateCampaign('needsAction', !campaign.needsAction);

      setCampaign({
        ...campaign,
        needsAction: !campaign.needsAction,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCampaignInfoChange = (event) => {
    const { id, value } = event.target;

    setCampaign({ ...campaign, [id]: value });
    debouncedCampaignUpdate(id, value);
  };

  const handleProviderChange = (event) => {
    const pcpId = event.target.value;
    setCampaign({ ...campaign, pcp: { id: pcpId, name: getProviderName(pcpId) } });
    updateCampaign('pcpId', pcpId);
  };

  const debouncedCampaignUpdate = useCallback(
    debounce((key, value) => updateCampaign(key, value), DEBOUNCE_TIME),
    []
  );

  const displayProvider = () => {
    if (campaign?.pcp) {
      return campaign.pcp.id;
    }

    return null;
  };

  const createLinesFromString = (string) => {
    if (string) {
      const lines = string.split('\n');

      return (
        <div>
          {lines.map((line) => (
            <p key={line}>{line}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const toggleWizard = () => {
    setShowWizard(!showWizard);
  };

  const isMinor = () => {
    if (campaign.isMinor) {
      return (
        <Tooltip
          title={
            <div
              style={{
                whiteSpace: 'pre-line',
                fontSize: '14px',
              }}
            >
              Patient is a minor
            </div>
          }
          placement="bottom"
          arrow
        >
          <InfoOutlinedIcon sx={{ color: 'orange' }} />
        </Tooltip>
      );
    }

    return null;
  };

  const addDate = () => {
    const today = dayjs().format('M/D/YY');
    const updatedNotes = `${campaign.notes}${today}: `;

    setCampaign({
      ...campaign,
      notes: updatedNotes,
    });
  };

  const formatAddress = (address) => {
    const addressParts = address.split(', ');
    const street = addressParts.slice(0, -3).join(', ');
    const cityStateZip = addressParts.slice(-3).join(', ');

    return (
      <div>
        <div>{street}</div>
        <div>{cityStateZip}</div>
      </div>
    );
  };

  const displayDisqualifiers = (disqualifiers) => {
    return (
      <Tooltip
        title={
          <div
            style={{
              whiteSpace: 'pre-line',
              fontSize: '16px',
            }}
          >
            <b>Ingestion Flags:</b>
            <p />
            {disqualifiers.map((disqualifier) => (
              <div>{disqualifier}</div>
            ))}
          </div>
        }
        placement="bottom"
        arrow
      >
        <ErrorOutlineIcon className="warning" />
      </Tooltip>
    );
  };

  const displayReferral = (referral) => {
    const provider = getProviderInfo(referral.providerId) || {};
    const specialty = getSpecialtyInfo(referral.followupSpecialtyId) || {};

    return (
      <div key={referral.id} className="provider">
        <div className="provider-info">
          <div className="names">
            <div className="name">{provider.displayName}</div>
            <div className="specialty">{specialty.name}</div>
          </div>

          <div className="status">
            <FormControl size="small">
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={referral.status}
                onChange={(event) => updateReferralStatus(event, referral.id)}
                disabled={!referral.activated} // Disable when not activated
              >
                <ListSubheader>Closed</ListSubheader>
                <MenuItem value="HasProvider">Independent Provider</MenuItem>
                <MenuItem value="NoAction">No Action</MenuItem>
                <MenuItem value="NoResponse">No Response</MenuItem>
                <MenuItem value="BookBySMS">Booked - SMS</MenuItem>
                <MenuItem value="BookByPhone">Booked - Phone</MenuItem>
                <MenuItem value="ConsentRefused">Consent Refused</MenuItem>
                <ListSubheader>Ongoing</ListSubheader>
                <MenuItem value="ConsentPending">Consent Pending</MenuItem>
                <MenuItem value="SMSOngoing">Text Ongoing</MenuItem>
                <MenuItem value="ReadyForCall">Ready for Call</MenuItem>
                <MenuItem disabled value="Error">
                  Error
                </MenuItem>
                <MenuItem disabled value="ManualOverride">
                  Manual Override
                </MenuItem>
                <MenuItem value="OtherCall">Other Call</MenuItem>
                <ListSubheader>Not in Scope</ListSubheader>
                <MenuItem value="NoActiveNumber">No Active Number</MenuItem>
                <MenuItem value="HasExistingAppointment">Has Existing Visit</MenuItem>
                <MenuItem value="NotInScope">Other</MenuItem>
              </Select>
            </FormControl>
            {referral.disqualifiers.length ? displayDisqualifiers(referral.disqualifiers) : null}
          </div>
        </div>

        <div className="departments">
          {provider?.departments?.map((department) => (
            <div key={department.id} className="address">
              <div className="department">
                {department.name}
                <p>{department.phone}</p>
              </div>
              {formatAddress(department.address)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return loading ? (
    <div className="container">
      <Loader />
    </div>
  ) : (
    <>
      <div className="campaign">
        <div className="campaign-header">
          <div className="patient-info">
            <p>
              <b>Name:</b> {campaign.lastName}, {campaign.firstName}
            </p>
            <p>
              <b>Date of Birth:</b> {campaign.dob} {isMinor()}
            </p>
            <p>
              <b>EHR ID:</b> {campaign.ehrId}
            </p>
          </div>

          <div className="actions">
            {userType === USER_ROLES.ADMIN && (
              <Button variant="contained" onClick={() => setIsAuditOpen(!isAuditOpen)}>
                Audit
              </Button>
            )}
            {userType === USER_ROLES.ADMIN && (
              <Button variant="contained" onClick={() => navigate(`/patients/${campaign.patientId}`)}>
                Profile
              </Button>
            )}

            {isEmpty(campaign.tag) ? (
              <Button className="tag empty" variant="contained" onClick={updatePatientTag}>
                Flag
              </Button>
            ) : (
              <Button className="tag saved" variant="contained" onClick={updatePatientTag}>
                Flagged
              </Button>
            )}

            <IconButton onClick={toggleNeedsAction}>
              <ErrorOutlineIcon className={campaign.needsAction ? 'red' : ''} />
            </IconButton>
          </div>
        </div>

        <div className="section">
          <h3>Campaign</h3>

          <div className="info">
            <div className="input">
              <FormControl size="small">
                <InputLabel>PCP</InputLabel>
                <Select label="PCP" className="provider" value={displayProvider()} onChange={handleProviderChange}>
                  {providers.map((provider) => (
                    <MenuItem key={provider.id} id={provider.id} value={provider.id}>
                      {provider.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="status">
              <TextField label="Status" size="small" value={mapEngagementStatus(campaign.status)} />
            </div>

            <div className="input">
              <TextField
                label="Discharge Date"
                size="small"
                value={formatDatetime(campaign.dischargeDate, facility.timezone)}
              />
            </div>
          </div>
        </div>

        <div className="section">
          <PhoneNumbers
            campaignId={campaignId}
            campaignNumber={campaign.phone}
            numbers={campaign.phoneNumbers}
            patientId={patientId}
            getCampaign={getCampaign}
          />
        </div>

        <div className="section">
          <ContactAttempts campaignId={campaignId} />
        </div>

        <div className="section diagnoses-payers-comments">
          <div className="header">
            <h3>Discharge Details</h3>
            {collapseDischargeDetails ? (
              <IconButton onClick={() => setCollapseDischargeDetails(!collapseDischargeDetails)}>
                <ExpandMoreIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => setCollapseDischargeDetails(!collapseDischargeDetails)}>
                <ExpandLessIcon />
              </IconButton>
            )}
          </div>

          {collapseDischargeDetails ? null : (
            <div className="categories">
              <div className="category">
                <h4>PCP</h4>
                <p>{campaign?.pcp?.name}</p>
              </div>

              <div className="category">
                <h4>Diagnoses</h4>
                {createLinesFromString(campaign.diagnosis)}
              </div>

              <div className="category">
                <h4>Payers</h4>
                {createLinesFromString(campaign.payer)}
              </div>

              <div className="category">
                <h4>Comments</h4>
                {createLinesFromString(campaign.comments)}
              </div>
            </div>
          )}
        </div>

        <div className="section referred-providers">
          <h3>
            Referred Follow-up Providers
            <Button variant="text" className="providers-button" onClick={() => setOpenDrawer(true)}>
              View All Providers
            </Button>
          </h3>

          {campaign.referrals.map((referral) => displayReferral(referral))}
        </div>

        {campaign.referrals.length > 0 && (
          <>
            <PastPatientAppointments id={campaignId} type="campaign" />

            <PatientAppointments id={campaignId} type="campaign" />

            <Appointments
              patient={{
                dob: campaign.dob,
                firstName: campaign.firstName,
                lastName: campaign.lastName,
                id: campaign.patientId,
              }}
              campaignId={campaignId}
              referrals={campaign.referrals}
              getCampaign={getCampaign}
            />
          </>
        )}

        <div className="section">
          <h3>Campaign Notes</h3>
          <div className="notes">
            <TextField
              id="notes"
              multiline
              rows={8}
              value={campaign.notes || ''}
              onChange={handleCampaignInfoChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        position: 'absolute',
                        bottom: 8,
                        left: 8,
                        color: '$primary-color',
                      }}
                      onClick={addDate}
                    >
                      <TodayIcon className="primary-color" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <MessageHistory
          campaign={campaign}
          getCampaignSilently={getCampaignSilently}
          setCampaign={setCampaign}
          phoneNumber={campaign.phone}
          referrals={campaign.referrals}
        />

        <ProvidersDrawer open={openDrawer} setOpenDrawer={setOpenDrawer} />

        <div className="wizard-button">
          <Fab size="medium" onClick={toggleWizard}>
            <QuestionMarkIcon />
          </Fab>
        </div>

        {showWizard ? <PatientWizard /> : null}
      </div>

      <BottomSheet isOpen={isAuditOpen}>
        <CampaignAudit campaignId={campaignId} isOpen={isAuditOpen} onClose={() => setIsAuditOpen(false)} />
      </BottomSheet>
    </>
  );
}
