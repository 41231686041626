import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef, useState } from 'react';

import HiroApi from '../../HiroApi';
import Loader from '../Loader/Loader';

export default function AudioPlayer({ attempt, index, recording, updateRecordingReadStatus }) {
  const { getAccessTokenSilently } = useAuth0();
  const audioRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const getRecording = async (recordingSid) => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const recordingData = await HiroApi.getRecording(recordingSid, token);
      const blob = new Blob([recordingData], { type: 'audio/mpeg' });
      const blobUrl = URL.createObjectURL(blob);

      if (audioRef.current) {
        audioRef.current.src = blobUrl;
        await new Promise((resolve, reject) => {
          audioRef.current.oncanplaythrough = resolve;
          audioRef.current.onerror = reject;
        });
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handlePlay = async () => {
    if (!audioRef.current?.src) {
      const success = await getRecording(recording.sid);

      if (!success) return;
    }

    try {
      await audioRef.current.play();
      setIsPlaying(true);

      if (!recording.isRead) {
        await updateRecording({ isRead: true });
      }
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  const updateRecording = async (props) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.updateRecording(recording.id, props, token);
      updateRecordingReadStatus(attempt, index);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    audioRef.current.currentTime = 0;
  };

  const renderIconButton = () => {
    if (loading) {
      return (
        <IconButton>
          <Loader size={20} />
        </IconButton>
      );
    }

    if (isPlaying) {
      return (
        <Badge color="error" variant="dot" invisible={!recording?.isRead}>
          <IconButton onClick={handlePause}>
            <PauseCircleOutlinedIcon className="gray" />
          </IconButton>
        </Badge>
      );
    }

    return (
      <Tooltip title={recording?.source === 'RecordVerb' ? 'Voicemail' : 'Recording/Voicemail'}>
        <Badge color="error" variant="dot" invisible={recording?.isRead}>
          <IconButton onClick={handlePlay}>
            <PlayCircleOutlinedIcon className="green" />
          </IconButton>
        </Badge>
      </Tooltip>
    );
  };

  return (
    <div className="audio-player">
      <audio ref={audioRef} onEnded={handleEnded}>
        <track kind="captions" />
      </audio>

      {renderIconButton()}
    </div>
  );
}
