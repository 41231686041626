import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { formatDatetime } from '../../utils/helpers';

export default function CampaignAudit({ campaignId, isOpen, onClose }) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const componentRef = useRef(null);

  const getCampaignHistory = async () => {
    try {
      setLoading(true);

      const token = await getAccessTokenSilently();
      const campaignHistory = await HiroApi.getAuditHistory({ campaignId }, token);

      setHistory(campaignHistory);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getCampaignHistory(campaignId);
    }
  }, [campaignId, isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target) && isOpen) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <div className="campaign-audit" ref={componentRef}>
      <div className="header">
        <h3>Campaign History</h3>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <TableContainer sx={{ maxHeight: '300px' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">User</TableCell>
                <TableCell align="center">Date/Time</TableCell>
                <TableCell align="center">Change</TableCell>
                <TableCell align="center">Action</TableCell>
                <TableCell align="center">Category</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map(({ id, user, dateTime, change, action, category }) => (
                <TableRow key={id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' } }}>
                  <TableCell align="center">{user}</TableCell>
                  <TableCell align="center">{formatDatetime(dateTime)}</TableCell>
                  <TableCell align="center">{change}</TableCell>
                  <TableCell align="center">{action}</TableCell>
                  <TableCell align="center">{category}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

CampaignAudit.propTypes = {
  campaignId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

CampaignAudit.defaultProps = {
  isOpen: false,
  onClose: () => {},
};
