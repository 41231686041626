import './style.scss';

import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function BottomSheet({ children, isOpen }) {
  return (
    <Paper elevation={5} className={`bottom-sheet ${isOpen ? 'open' : 'closed'}`}>
      {children}
    </Paper>
  );
}

BottomSheet.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
